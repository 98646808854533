<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <div>
      <b-navbar class="shadow-lg py-1" toggleable="md">
        <div class="container">
          <b-navbar-nav class="mr-auto w-75">
            <b-nav-item :to="{ name: 'landing-mechtech' }" class="mx-1">
              <img :src="require('@/assets/images/logo/logo-text.png')" alt="logo-mechtech" style="width: 25%">
            </b-nav-item>
          </b-navbar-nav>

          <b-navbar-toggle target="nav-collapse" />

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item :to="{ name: 'landing-mechtech' }" class="mx-1 nav-text">
                Home
              </b-nav-item>
              <b-nav-item :to="{ name: 'landing-subscription-class' }" class="mx-1 nav-text">
                Subscription Class
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>

    <transition mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
}
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/themes/bordered-layout.scss";

.navbar-toggler-icon {
  width: 1em !important;
  height: 1em !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #990000;
}

.nav-text {
  font-size: 1rem;
  font-weight: 500;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.3rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.3rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1.3rem;
  }
}
</style>
